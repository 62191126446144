import { combineReducers } from "redux"
import authReducer from "./auth.reducer"
import LoaderReducer  from "./loader.reducer"
import HmdDeviceReducer from "./hmdDevices.reducer"
import AssessmentReducer  from "./assessment.reducer"
import ModalReducer  from "./modal.reducer"

export default combineReducers({
  loader: LoaderReducer,
  auth: authReducer, 
  hmdDevices: HmdDeviceReducer,
  assessment: AssessmentReducer,
  modals: ModalReducer,
})
