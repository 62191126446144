import React, { useEffect } from "react"
import { clearAuthToken } from "../../services/localstorage/localStorage.service"
import { ROUTES } from '../../shared/constants/routes.constants'
import { connect } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { withRouter } from "react-router-dom"
import { bindActionCreators } from 'redux'
import { setModalWithType } from "../../actions/modal.action"
import { MODALS } from "../../shared/constants/actions.constants"

export const Header = (props) => {
  
  const onLogoutClick = event => {
    event.preventDefault()
    clearAuthToken()
    window.location.href = ROUTES.LOGIN
  }

  return (
    <header className="header d-flex">
      <div className="header-logo d-flex justify-content-center">
        <img className="header-logo-image" src="/assets/images/logowhite.png" alt="Eadietech Logo" />
      </div>
      <div className="header-nav d-flex justify-content-between w-100">
        <div className="header-nav-left"></div>
        <div className="header-nav-right">
          <ul>
            <li className = "nav-item dropdown">
              <a className = "header-nav-avatar" id="profileDropdown" data-toggle="dropdown" href = "#!">
                <img className = "rounded-circle" src="/assets/images/profile-placeholder.jpg" alt="profile" />
              </a>
              <div className = "dropdown-menu dropdown-menu-right profile-dropdown">
                <div className = "user-info">
                  <img className = "rounded-circle mx-auto" src="/assets/images/profile-placeholder.jpg" alt="profile" />
                  <p><strong>{ props.loggedInUser.firstName } { props.loggedInUser.lastName }</strong></p>
                  <p>{ props.loggedInUser.email }</p>
                </div>
                <a className = "dropdown-item" href="#!" onClick = { onLogoutClick }>Logout</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </header>
  )

}

const mapStateToProps = ({ }) => ({
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setModalWithType
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withNamespaces()(Header)))
// export default Header;
