import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { setModalWithType } from '../../actions/modal.action';

export const LoginHeader = props => {
  return (
    <header className="header d-flex">
      <div className="header-logo d-flex justify-content-center">
        <img
          className="header-logo-image"
          src="/assets/images/logowhite.png"
          alt="Eadietech Logo"
        />
      </div>
      <div className="header-nav d-flex justify-content-between w-100">
        <div className="header-nav-left"></div>
        <div className="header-nav-right">
          <ul></ul>
        </div>
      </div>
    </header>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setModalWithType,
    },
    dispatch,
  );

const mapStateToProps = ({}) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withNamespaces()(LoginHeader)));
// export default Header;
